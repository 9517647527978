<template>
  <div class="w1092" v-if="item">
      <div class="board">
        <div class="boardrd first">
<!--          <span class="blc">스포츠북</span>-->
          <span class="ml10">{{item.msgTitle}}</span>
          <span class="newicon ml5">N</span>
        </div>
        <div class="boardrd">
          <img src="@/assets/img/detective-logo.png"><span class="ml10">{{dateFormat(item.sendDate)}}</span>
        </div>
        <div class="boardrd hauto">
          <p class="rdcon">{{item.msgDesc}}</p>
        </div>
      </div>
      <div class="boardbtn">
        <a class="blsbtn left" v-if="prevItem" @click="onMessageClick(prevItem)">{{$t('front.board.prev')}}</a>
        <a class="blksbtn center" @click="onMessageList(false)">{{$t('front.board.list')}}</a>
        <a class="blsbtn right" v-if="nextItem" @click="onMessageClick(nextItem)">{{$t('front.board.next')}}</a>
      </div>
    </div>
</template>

<script>

import { getMsgList, setMsgRead } from '@/api/board'
import { mapState } from 'vuex'

export default {
  name: 'messageRead',
  data () {
    return {
      prevItem: null,
      nextItem: null,
      item: null,
      list: null
    }
  },
  async created () {
    this.msgIdx = this.$route.params.msgIdx
    await this.loadList(1)
    if (this.list.length > 0) {
      for (let i = 0, iLen = this.list.length; i < iLen; i++) {
        const data = this.list[i]
        if (data.msgIdx.toString() === this.msgIdx) {
          this.item = data
          if (this.list[i + 1]) {
            this.prevItem = this.list[i + 1]
          }
          if (this.list[i - 1]) {
            this.nextItem = this.list[i - 1]
          }
        }
      }
    }

    this.setRead()
  },
  computed: {
    ...mapState([
      'userData'
    ])
  },
  methods: {
    async loadList (page) {
      const params = {
        page: page || this.pageInfo.page
      }
      await getMsgList(params).then(response => {
        const result = response.data

        if (result.resultCode === '0') {
          const list = result.data.list
          console.log(result)
          if (result.data.pageInfo) {
            this.pageInfo = result.data.pageInfo
          }
          this.list = list
        }
      })
    },
    setRead () {
      this.item.status = 'R'

      const param = {
        msgIdx: this.item.msgIdx,
        recieveId: this.item.recieveId
      }
      setMsgRead(param).then(response => {

      })
    },
    onMessageList (isMobile) {
      if (isMobile) {
        // this.goPageByName('msgMain')
        location.href = '/msg'
      } else {
        this.goPageByName('messageList')
      }
    },
    onMessageClick (item, isMobile) {
      item.list = JSON.stringify(this.list)
      if (isMobile) {
        location.href = '/msgRead/' + item.msgIdx
      } else {
        // this.goPageByName('messageRead', item)
        location.href = '/mypage/message/read/' + item.msgIdx
      }
    }
  }
}
</script>
<style scoped>
.boardrd:last-child {padding: 20px;}
</style>
<style scoped src="@/styles/common.css"></style>
<style scoped src="@/styles/subcommon.css"></style>
